import Layout from '../components/Layout';
import MasonryGallery from '../components/MasonryGallery';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import './portfolio.scss';

interface Props {
  data: any;
}

const Portfolio: FC<Props> = ({ data }: Props) => (
  <Layout>
    <MasonryGallery gallery={data.contentfulGallery.gallery} />
  </Layout>
);

export const query = graphql`
  query PortfolioPage {
    contentfulGallery(category: { eq: "Home" }) {
      category
      gallery {
        full: gatsbyImageData(
          height: 1334
          width: 2000
          placeholder: BLURRED
          outputPixelDensities: [0.25, 0.5, 1]
        )
        title
      }
    }
  }
`;

export default Portfolio;
